import { useEffect, useRef } from 'react'

import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'

const WAITING_TIMEOUT = 30 * 1000

function Video({ src }) {
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const statusRef = useRef('DEFAULT')
  const waitingTimer = useRef(0)
  const waitingInterval = useRef(null)

  const options = {
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    muted: true,
    sources: [
      {
        src,
        type: 'application/x-mpegURL'
      }
    ]
  }

  const handlePlaying = () => {
    statusRef.current = 'PLAYING'
    // console.log('playing')

    clearInterval(waitingInterval.current)
    waitingTimer.current = 0
  }
  const handleWaiting = () => {
    statusRef.current = 'WAITING'
    // console.log('waiting')

    clearInterval(waitingInterval.current)
    waitingTimer.current = 0
    waitingInterval.current = setInterval(() => {
      // console.log('waiting', waitingTimer.current)
      if (statusRef.current === 'WAITING') {
        waitingTimer.current++
      } else {
        waitingTimer.current = 0
      }
      if (waitingTimer.current > WAITING_TIMEOUT / 1000) {
        const player = playerRef.current

        waitingTimer.current = 0

        player.autoplay(options.autoplay)
        player.src(options.sources.map(source => source.src + '?t=' + Date.now()))
      }
    }, 1000)
  }

  useEffect(() => {
    // console.log(src)
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js')
      videoRef.current.appendChild(videoElement)
      playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready')
      })
    } else {
      const player = playerRef.current

      player.autoplay(options.autoplay)
      player.src(options.sources)
    }

    playerRef.current.on('playing', handlePlaying)
    playerRef.current.on('play', handlePlaying)
    playerRef.current.on('waiting', handleWaiting)
    playerRef.current.on('error', handleWaiting)

    return () => {
      clearInterval(waitingInterval.current)
    }
  }, [videoRef, src])

  useEffect(() => {
    const player = playerRef.current

    return () => {
      clearInterval(waitingInterval.current)

      if (player && !player.isDisposed()) {
        player.dispose()

        playerRef.current.off('playing', handlePlaying)
        playerRef.current.off('play', handlePlaying)
        playerRef.current.off('waiting', handleWaiting)

        playerRef.current = null
      }
    }
  }, [playerRef])

  return <div className="video" ref={videoRef} />
}
export default Video
