import { useEffect, useState } from 'react'
import Video from './Video'

const REFRESH_TIMEOUT = 60 * 60 * 1000

function App() {
  const [streams, setStreams] = useState([
    {
      id: 1,
      url: 'http://3.77.167.151:1935/live/ie-prod-1.stream/playlist.m3u8'
    },
    {
      id: 2,
      url: 'http://3.77.167.151:1935/live/ie-prod-2.stream/playlist.m3u8'
    },
    {
      id: 3,
      url: 'http://3.77.167.151:1935/live/ie-prod-3.stream/playlist.m3u8'
    },
    {
      id: 4,
      url: 'http://3.77.167.151:1935/live/ie-prod-4.stream/playlist.m3u8'
    },
    {
      id: 5,
      url: 'http://3.77.167.151:1935/live/ie-prod-5.stream/playlist.m3u8'
    },
    {
      id: 6,
      url: 'http://3.77.167.151:1935/live/ie-prod-6.stream/playlist.m3u8'
    }
  ])

  useEffect(() => {
    setTimeout(() => {
      window.location.reload()
    }, REFRESH_TIMEOUT)
  }, [])

  return (
    <div className="wall">
      {streams.map(stream => (
        <div className="wall-item" key={stream.id}>
          <Video key={stream.id} src={stream.url} type="application/x-mpegURL" />
        </div>
      ))}
    </div>
  )
}

export default App
